import {
  Button,
  Columns,
  Column,
  Container,
  Hero,
  Form,
  Iframe,
  Image as ImageModule,
  Link,
  LinkList,
  PopularItems,
  Slide,
  Slider,
  Text,
} from "./modules";
import { type ModuleTypes, Modules } from "./modules/types";

export function contentGetModule(id: string, module: ModuleTypes) {
  switch (module.type) {
    case Modules.button:
      return <Button key={id} {...module.button} />;
    case Modules.column:
      return <Column key={id} {...module} />;
    case Modules.columns:
      return <Columns key={id} {...module} />;
    case Modules.container:
      return <Container key={id} {...module} />;
    case Modules.form:
      return <Form.Wrapper key={id} {...{ id, ...module }} />;
    case Modules.formText:
      return <Form.Field key={id} {...module} />;
    case Modules.formMessage:
      return <Form.Message key={id} {...module} />;
    case Modules.formEmail:
      return <Form.Field key={id} {...module} type="email" />;
    case Modules.formPhone:
      return <Form.Field key={id} {...module} type="tel" />;
    case Modules.formLocations:
      return <Form.Locations key={id} {...module} />;
    case Modules.hero:
      return <Hero key={id} {...module} />;
    case Modules.iframe:
      return <Iframe key={id} {...module} />;
    case Modules.image:
      return <ImageModule key={id} {...module} />;
    case Modules.linkList:
      return <LinkList key={id} {...module} />;
    case Modules.link:
      return <Link key={id} {...module} />;
    case Modules.popularItems:
      return <PopularItems key={id} {...module} />;
    case Modules.slider:
      return <Slider key={id} {...module} />;
    case Modules.slide:
      return <Slide key={id} {...module} />;
    case Modules.text:
      return <Text key={id} {...module} />;

    default:
      return null;
  }
}
