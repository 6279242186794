import { styled } from "@/components/content/stitches";

function Play() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <title>Play slider</title>
      <path
        d="M12.8136 9.17794C13.3876 9.57562 13.3876 10.4242 12.8136 10.8219L8.06958 14.1087C7.40638 14.5682 6.50008 14.0935 6.50008 13.2867L6.50008 6.71315C6.50008 5.90633 7.40638 5.43168 8.06958 5.89116L12.8136 9.17794Z"
        fill="white"
      />
    </svg>
  );
}

function Pause() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <title>Pause slider</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 7C9 6.44772 8.55228 6 8 6C7.44772 6 7 6.44772 7 7V13C7 13.5523 7.44772 14 8 14C8.55228 14 9 13.5523 9 13V7ZM13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7V13C11 13.5523 11.4477 14 12 14C12.5523 14 13 13.5523 13 13V7Z"
        fill="white"
      />
    </svg>
  );
}

function Previous() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <title>Previous slide</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 9.99999L11.6194 4.94252C11.9273 4.66543 12.4015 4.69039 12.6786 4.99827C12.9557 5.30615 12.9307 5.78037 12.6229 6.05746L8.24227 9.99999L12.6229 13.9425C12.9307 14.2196 12.9557 14.6938 12.6786 15.0017C12.4015 15.3096 11.9273 15.3346 11.6194 15.0575L6 9.99999Z"
        fill="white"
      />
    </svg>
  );
}

function Next() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <title>Next slide</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1211 10L8.50168 15.0575C8.1938 15.3346 7.71958 15.3096 7.44249 15.0017C7.16539 14.6938 7.19035 14.2196 7.49823 13.9425L11.8788 10L7.49824 6.05748C7.19035 5.78038 7.16539 5.30617 7.44249 4.99828C7.71958 4.6904 8.1938 4.66544 8.50168 4.94254L14.1211 10Z"
        fill="white"
      />
    </svg>
  );
}

interface Props {
  active: number;
  items: string[];
  showAutoPlayControls: boolean;
  isAutoPlaying: boolean;
  onChange: (value: number) => void;
  onPlayStateChange: () => void;
}

export function Controls({
  active,
  items,
  showAutoPlayControls,
  isAutoPlaying,
  onChange,
  onPlayStateChange,
}: Props) {
  const lastSlide = items.length - 1;

  const decrement = () => {
    const previous = active > 0 ? active - 1 : lastSlide;
    onChange(previous);
  };

  const increment = () => {
    const next = active < lastSlide ? active + 1 : 0;
    onChange(next);
  };

  return (
    <Navigation>
      {showAutoPlayControls && (
        <NavigationItem
          css={{
            marginRight: "$2",
          }}
          onClick={() => onPlayStateChange()}
        >
          {!isAutoPlaying && <Play />}
          {isAutoPlaying && <Pause />}
        </NavigationItem>
      )}

      <NavigationItem onClick={decrement}>
        <Previous />
      </NavigationItem>
      {items.map((id, index) => (
        <NavigationItem key={id} onClick={() => onChange(index)}>
          {index + 1}
          <Dot active={active === index} />
        </NavigationItem>
      ))}

      <NavigationItem onClick={increment}>
        <Next />
      </NavigationItem>
    </Navigation>
  );
}

const Navigation = styled("div", {
  alignItems: "center",
  bottom: "0",
  display: "flex",
  justifyContent: "center",
  padding: "$4",
  position: "absolute",
  left: "0",
  right: "0",
  zIndex: "$10",
});

const NavigationItem = styled("button", {
  appearance: "none",
  backgroundColor: "transparent",
  border: "none",
  color: "transparent",
  cursor: "pointer",
  display: "grid",
  height: "$5",
  lineHeight: 0,
  gridTemplateColumns: "1fr",
  gridTemplateRows: "1fr",
  padding: 0,
  placeItems: "center",
  width: "$5",

  "& *": {
    gridColumn: "1 / -1",
    gridRow: "1 / -1",
  },
});

const Dot = styled("span", {
  borderRadius: "100%",
  display: "block",
  height: "$2",
  gridColumn: "1 / -1",
  gridRow: "1 / -1",
  width: "$2",

  variants: {
    active: {
      true: {
        backgroundColor: "white",
      },
      false: {
        backgroundColor: "rgba(255,255,255,.5)",
      },
    },
  },
});
