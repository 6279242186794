import { useField } from "formik";
import { Form } from "@/components/ui";

interface Props {
  label: string;
  name: string;
  required: boolean;
}

export function Message({ label, name, required }: Props) {
  const [field, meta] = useField({ name, required });

  return (
    <Form.Textarea
      {...field}
      name={name}
      label={label}
      required={required}
      error={meta.touched && meta.error ? meta.error : undefined}
    />
  );
}
