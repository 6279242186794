export function getAlignmentVariants() {
  return [
    {
      direction: "vertical",
      alignment: "left-top",
      css: {
        alignItems: "flex-start",
        justifyContent: "flex-start",
      },
    },
    {
      direction: "vertical",
      alignment: "center-top",
      css: {
        alignItems: "center",
        justifyContent: "flex-start",
        textAlign: "center",
      },
    },
    {
      direction: "vertical",
      alignment: "right-top",
      css: {
        alignItems: "flex-end",
        justifyContent: "flex-start",
      },
    },
    {
      direction: "vertical",
      alignment: "left-center",
      css: {
        alignItems: "flex-start",
        justifyContent: "center",
      },
    },
    {
      direction: "vertical",
      alignment: "center-center",
      css: {
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      },
    },
    {
      direction: "vertical",
      alignment: "right-center",
      css: {
        alignItems: "flex-end",
        justifyContent: "center",
      },
    },
    {
      direction: "vertical",
      alignment: "left-bottom",
      css: {
        alignItems: "flex-start",
        justifyContent: "flex-end",
      },
    },
    {
      direction: "vertical",
      alignment: "center-bottom",
      css: {
        alignItems: "center",
        justifyContent: "flex-end",
        textAlign: "center",
      },
    },
    {
      direction: "vertical",
      alignment: "right-bottom",
      css: {
        alignItems: "flex-end",
        justifyContent: "flex-end",
      },
    },

    {
      direction: "horizontal",
      alignment: "left-top",
      css: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
      },
    },
    {
      direction: "horizontal",
      alignment: "center-top",
      css: {
        justifyContent: "center",
        alignItems: "flex-start",
        textAlign: "center",
      },
    },
    {
      direction: "horizontal",
      alignment: "right-top",
      css: {
        justifyContent: "flex-end",
        alignItems: "flex-start",
      },
    },
    {
      direction: "horizontal",
      alignment: "left-center",
      css: {
        justifyContent: "flex-start",
        alignItems: "center",
      },
    },
    {
      direction: "horizontal",
      alignment: "center-center",
      css: {
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      },
    },
    {
      direction: "horizontal",
      alignment: "right-center",
      css: {
        justifyContent: "flex-end",
        alignItems: "center",
      },
    },
    {
      direction: "horizontal",
      alignment: "left-bottom",
      css: {
        justifyContent: "flex-start",
        alignItems: "flex-end",
      },
    },
    {
      direction: "horizontal",
      alignment: "center-bottom",
      css: {
        justifyContent: "center",
        alignItems: "flex-end",
        textAlign: "center",
      },
    },
    {
      direction: "horizontal",
      alignment: "right-bottom",
      css: {
        justifyContent: "flex-end",
        alignItems: "flex-end",
      },
    },
  ];
}
