import { styled } from "@/components/content/stitches";
import { Stack } from "@/components/ui";
import { Render } from "@/components/uielements/render";
import { useConfigOverride } from "@/redux/cmsConfig";
import { getFontStyles } from "@/redux/cmsConfig/utils";

interface Props {
  image: string | null;
  name: string;
  description?: string;
  calories?: string;
  price?: string | null;
  onClick: () => void;
}

export function Card({
  image,
  name,
  description,
  calories,
  price,
  onClick,
}: Props) {
  const { primary_border_color, border_radius } = useConfigOverride("global");
  const { primary_font } = useConfigOverride("text");
  const { color } = getFontStyles(primary_font);
  const {
    hide_calories,
    hide_price,
    hide_description,
    hide_description_mobile,
    hide_image,
  } = useConfigOverride("menu_product_card");

  return (
    <Container
      onClick={onClick}
      direction="vertical"
      css={{
        border: `1px solid ${primary_border_color}`,
        borderRadius: `${border_radius}px`,
        color,
      }}
    >
      <Render condition={!hide_image}>
        <ImageContainer>
          {image && <Image src={image} alt={name} />}
        </ImageContainer>
      </Render>

      <Content
        css={{
          flex: "1 0 0",
          fontSize: "$text200",
          padding: "$3 $2 $6",
        }}
        direction="vertical"
      >
        <Title>{name}</Title>

        <Render condition={!hide_description}>
          <Description hideOnMobile={hide_description_mobile}>
            {description}
          </Description>
        </Render>

        <Stack
          css={{
            gap: 0,
            marginTop: "auto",
            flexDirection: "column",
            "@sm": { flexDirection: "row", gap: "$2" },
          }}
          position="center"
        >
          <Render condition={!hide_price}>
            <strong>{price}</strong>
          </Render>

          <Render condition={!hide_calories}>
            <span>{calories}</span>
          </Render>
        </Stack>
      </Content>
    </Container>
  );
}

const ImageContainer = styled("div", {
  aspectRatio: "1 / 1",
  backgroundColor: "$shade200",
  overflow: "hidden",
  transition: "all .4s ease",
  width: "100%",
});

const Image = styled("img", {
  display: "block",
  height: "100%",
  objectFit: "cover",
  transition: "all .4s ease",
  width: "100%",
  maxWidth: 300,
});

const Content = styled(Stack, {
  transition: "all .2s ease",
});

const Title = styled("h3", {
  fontSize: "1rem",
  margin: 0,
});

const Description = styled("p", {
  margin: 0,

  variants: {
    hideOnMobile: {
      false: {
        display: "block",
      },
      true: {
        display: "none",

        "@md": {
          display: "block",
        },
      },
    },
  },
});

const Container = styled(Stack, {
  cursor: "pointer",
  height: "100%",
  overflow: "hidden",
  textAlign: "center",
  transition: "all .4s ease",
  transform: "translate3d(0,0,0)",

  [`&:hover ${Image}`]: {
    transform: "scale(1.1)",
  },
  [`&:hover ${ImageContainer}`]: {
    backgroundColor: "$shade300",
  },
});
