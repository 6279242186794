import type * as Stitches from "@stitches/react";
import { type ImageField } from "./types";
import { resizeImage } from "@/utils/imageHelper";

export function getBackgroundStyles(
  image?: ImageField,
  mobileImage?: ImageField,
  color?: string
) {
  const mainBgImage = image?.url
    ? `url(${resizeImage(image.url, { width: 1800 })})`
    : "none";

  let properties: Stitches.CSS = {
    backgroundColor: color ? color : "transparent",
    backgroundImage: mainBgImage,
  };

  // add mobile override
  if (mobileImage?.url) {
    properties = {
      ...properties,
      backgroundImage: `url(${resizeImage(mobileImage.url, { width: 1800 })})`,

      "@sm": {
        backgroundImage: mainBgImage,
      },
    };
  }

  return properties;
}
