import { useState, useEffect } from "react";

interface Props {
  count: number;
  perPage?: number;
}

/**
 * Encapsulates generic slider functionality used in various components
 * The goal of this hook is to handle basic slider requirements while not
 * dictating the UI.
 *
 * At the basic level, the [*]Prop objects (including dots) are all thats required to use this hook
 *
 * ```
 * const { backProps, nextProps, itemProps, dots } = useSlider({count: 12, perPage: 5});
 * ```
 * @param {Props} props.count the total number of items
 * @param {Props} props.perPage the number of items to show per page (slide)
 */
export function useSlider({ count, perPage = 1 }: Props) {
  const [page, setPage] = useState(0);
  const [disablePrevious, setDisablePrevious] = useState(true);
  const [disableNext, setDisableNext] = useState(true);
  const [offset, setOffset] = useState(0);
  const [pages, setPages] = useState(Math.ceil(count / perPage));

  function goBack() {
    setPage((state) => {
      if (disablePrevious) {
        return state;
      }

      // return state > 0 ? state - 1 : 0;
      return state - 1;
    });
  }

  function goForward() {
    setPage((state) => {
      if (disableNext) {
        return state;
      }

      return state + 1;
    });
  }

  useEffect(() => {
    const isFirstPage = page === 0;
    const isLastPage = page === pages - 1;
    setDisablePrevious(isFirstPage ? true : false);
    setDisableNext(isLastPage ? true : false);

    if (count <= perPage) {
      return;
    }

    if (isLastPage && count % perPage !== 0) {
      /*
       * This hook allows us to show multiple items per page.
       * For example, 15 total items with 5 per page will have 3 pages
       *
       * However, if there is a remainder (ie: 12 total items with 5 per page),
       * we don't want to show a gap on the last page the end.
       *
       * This check uses the `%` modulo operator to grab the difference (12%5 = 2)
       * and calculate the correct final offset
       *
       * Note: this doesn't work when we have a remainder of 0,
       * hence the if condition above to exclude even sets.
       *
       */
      setOffset((page * perPage - (perPage - (count % perPage))) * -100);
    } else {
      setOffset(page * perPage * -100); // each item is treated individually, so we offset the full value
    }
  }, [page, count, perPage, pages]);

  useEffect(() => {
    setPages(Math.ceil(count / perPage));
  }, [count, perPage]);

  return {
    // spread over the button that controls going back a page
    backProps: {
      onClick: goBack,
      disabled: disablePrevious,
    },
    // spread over the button that controls going forward a page
    nextProps: {
      onClick: goForward,
      disabled: disableNext,
    },
    // spread over each item, all items should be in a straight list
    // with the appropriate css applied based on the requirements
    // see: popular products widget as an example
    itemProps: {
      style: {
        transform: `translateX(${offset}%)`,
      },
    },
    // map over and spread props to each dot button. Apply the label as the button label
    dots: Array.from(Array(pages).keys()).map((item) => ({
      label: item + 1, // since we start from 0, we want the label to be start 1 above so we have 1, 2, 3, etc
      props: {
        onClick: () => setPage(item),
        active: Boolean(page === item),
      },
    })),
    // exposed low level operations for unique situations
    pages,
    page,
    setPage,
  };
}
