import { Button } from "./button";
import { Column } from "./column";
import { Columns } from "./columns";
import { Container } from "./container";
import { FormWrapper } from "./form/form";
import { Locations as FormLocations } from "./form/locations";
import { Message as FormMessage } from "./form/message";
import { Field as FormField } from "./form/text";
import { Hero } from "./hero/hero";
import { Iframe } from "./iframe/iframe";
import { Image } from "./image";
import { Link } from "./link-list/link";
import { LinkList } from "./link-list/link-list";
import { PopularItems } from "./popular-items";
import { Slide } from "./slider/slide";
import { Slider } from "./slider/slider";
import { Text } from "./text";

const Form = {
  Wrapper: FormWrapper,
  Field: FormField,
  Message: FormMessage,
  Locations: FormLocations,
};

export {
  Button,
  Column,
  Columns,
  Container,
  Hero,
  Form,
  Iframe,
  LinkList,
  Link,
  Image,
  PopularItems,
  Slide,
  Slider,
  Text,
};
