import { type ReactNode, useEffect, useRef } from "react";
import { styled } from "./stitches";

interface Props {
  children: ReactNode;
  id: string;
  isHighlighted?: boolean;
}
export function Preview({ children, isHighlighted = false }: Props) {
  const ref = useRef<HTMLDivElement>();
  // @TODO: we may implement this post-MVP as we make the previewer and editor more integrated
  // const { sendParentMessage } = useWindowMessaging(contentGetCmsUrl(), [id]);

  useEffect(
    function () {
      if (isHighlighted && ref.current) {
        ref.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    },
    [isHighlighted]
  );

  return (
    <Container
      // @ts-expect-error
      ref={ref}
    >
      <Contents>{children}</Contents>{" "}
      <Overlay
        isHighlighted={isHighlighted}
        // @TODO: we may implement this post-MVP as we make the previewer and editor more integrated
        // onMouseEnter={function () {
        //   sendParentMessage({
        //     type: WindowMessageEvents.highlightModule,
        //     id,
        //   });
        // }}
        // onMouseLeave={function () {
        //   sendParentMessage({
        //     type: WindowMessageEvents.highlightModule,
        //   });
        // }}
        // onClick={function () {
        //   sendParentMessage({
        //     type: WindowMessageEvents.selectModule,
        //     id,
        //   });
        // }}
      />
    </Container>
  );
}

const Overlay = styled("button", {
  backgroundColor: "rgba(13,108,242,.3)",
  border: "3px solid #0D6CF2",
  cursor: "pointer",
  position: "absolute",
  opacity: 0,
  inset: 0,
  transition: "all .1s ease",
  zIndex: "$20",

  variants: {
    isHighlighted: {
      true: {
        opacity: 1,
      },
    },
  },
});

const Container = styled("div", {
  position: "relative",

  [`&:hover ${Overlay}`]: {
    opacity: 1,
  },
});

const Contents = styled("div", {
  pointerEvents: "none",
});
