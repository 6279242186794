import { useField } from "formik";
import { string, object } from "yup";
import { Form } from "@/components/ui";
import { VALIDATION_STRINGS } from "@/constants/validation";

interface Props {
  label: string;
  name: string;
  required: boolean;
  type: string;
}

const emailSchema = object({
  value: string().email(),
});

const phoneSchema = object({
  value: string().matches(
    /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
  ),
});

export function Field({ label, name, required, type = "text" }: Props) {
  const [field, meta] = useField({
    name,
    type,
    validate: async (value) => {
      if (value === "") {
        return;
      }

      if (type === "email") {
        const isValid = await emailSchema.isValid({ value });

        return !isValid ? VALIDATION_STRINGS.EMAIL : undefined;
      }

      if (type === "tel") {
        const isValid = await phoneSchema.isValid({ value });

        return !isValid ? VALIDATION_STRINGS.PHONE : undefined;
      }

      return;
    },
  });

  return (
    <Form.Input
      {...field}
      label={label}
      name={name}
      required={required}
      error={meta.touched && meta.error ? meta.error : undefined}
    />
  );
}
