import { type ModuleTypes } from "./modules/types";
import { Preview } from "./preview";
import { styled } from "./stitches";
import { contentGetModule } from "./utils";

interface Props {
  content?: {
    order: string[];
    modules: Record<string, ModuleTypes>;
  };
  enablePreviewerTools?: boolean;
  highlighedModule?: string;
}

export function Content({
  content,
  enablePreviewerTools = false,
  highlighedModule,
}: Props) {
  if (!content?.order) {
    return null;
  }

  return (
    <Container>
      {content.order.map((id: string) => {
        const currentModule = content?.modules[id];

        if (enablePreviewerTools) {
          return (
            <Preview id={id} isHighlighted={highlighedModule === id}>
              {contentGetModule(id, currentModule)}
            </Preview>
          );
        }

        return <>{contentGetModule(id, currentModule)}</>;
      })}
    </Container>
  );
}

const Container = styled("div", {
  position: "relative",
  zIndex: "$0",
});
