import { Button as Component } from "@/components/content/fields/button/button";
import { type ButtonField } from "@/components/content/modules/types";

export function Button({ style = "primary", url }: ButtonField) {
  const { reference, label } = url;

  return (
    <Component
      style={style}
      href={reference?.url}
      newWindow={reference?.newWindow}
      label={label}
      type={reference?.type}
    />
  );
}
