import {
  type TypographyField,
  type UrlField,
} from "@/components/content/modules/types";
import { styled } from "@/components/content/stitches";

interface Props {
  url: UrlField;
  color: string;
  fontStyle: TypographyField;
}

export function Link({ url, color, fontStyle }: Props) {
  const { reference, label } = url;

  return (
    <Element
      css={{
        color: color ?? "inherit",
      }}
      typography={fontStyle}
      href={reference?.url ?? "#"}
      target={reference?.newWindow ? "_blank" : "_self"}
    >
      {label}
    </Element>
  );
}

const Element = styled("a", {
  variants: {
    typography: {
      primary: {
        fontFamily: "$primary",
        fontSize: "$primary",
      },
      secondary: {
        fontFamily: "$secondary",
        fontSize: "$secondary",
      },
    },
  },
  defaultVariants: {
    typography: "primary",
  },
});
