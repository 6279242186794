import { Column } from "./column";
import type * as Types from "./types";
import { getBackgroundStyles } from "@/components/content/modules/utils";
import { styled } from "@/components/content/stitches";

interface Props {
  content: {
    order: string[];
    modules: Record<string, Types.Container>;
  };
  layout: Types.LayoutField;
  columns: Types.ColumnField;
}

export function Columns({ content, layout, columns = "three" }: Props) {
  return (
    <Wrapper
      css={{
        ...getBackgroundStyles(
          layout?.backgroundImage,
          layout?.mobileBackgroundImage,
          layout?.backgroundColor
        ),
      }}
    >
      <Element columns={columns}>
        {content.order.map((id: string) => {
          const moduleItem = content.modules[id];

          return <Column key={id} {...moduleItem} />;
        })}
      </Element>
    </Wrapper>
  );
}

const Wrapper = styled("section", {});

const Element = styled("div", {
  display: "grid",
  width: "100%",

  variants: {
    columns: {
      one: {
        gridTemplateColumns: "1fr",
      },
      two: {
        "@md": {
          gridTemplateColumns: "repeat(2, 1fr)",
        },
      },
      three: {
        "@md": {
          gridTemplateColumns: "repeat(3, 1fr)",
        },
      },
      four: {
        "@md": {
          gridTemplateColumns: "repeat(2, 1fr)",
        },
        "@lg": {
          gridTemplateColumns: "repeat(4, 1fr)",
        },
      },
    },
  },
});
