import {
  type AnchorObject,
  type BlockObject,
  Elements,
  type ListItemObject,
  type ListObject,
  type TextObject,
} from "./types";
import { styled } from "@/components/content/stitches";

/*
 *
 * Maps an element size to a tag. This allows us to generate a better document markup
 * for content. However, in the future, tags should be controlled independently of size
 */
export function getTagFromSize(
  size: "normal" | "small" | "large" | "x-large" = "normal"
) {
  switch (size) {
    case "small":
      return "p";
    case "normal":
      return "p";
    case "large":
      return "h3";
    case "x-large":
      return "h2";
  }
}

/*
 *
 * Outputs the raw, inline text for every element.
 * This can be a link, span, paragraph, or heading
 */
function getFormattedText(props: TextObject | AnchorObject, index: number) {
  if (props.type === Elements.anchor) {
    return (
      <Anchor key={index} href={props.href} target={props.target}>
        {props.children.map((item, index) => getFormattedText(item, index))}
      </Anchor>
    );
  } else {
    const {
      bold = false,
      italic = false,
      size = "normal",
      color = undefined,
      text,
    } = props;

    if (text === "") {
      return <br key={index} />;
    }

    return (
      <FormattedText
        key={index}
        bold={bold}
        italic={italic}
        fontSize={size}
        css={{
          color: color ?? "inherit",
        }}
      >
        {text}
      </FormattedText>
    );
  }
}

/*
 *
 * Outputs block elements like <ul>, <ol>, and <li>
 * Passes through other elements like <h2> and <p> that are less structured.
 */
export function formatRichText(
  { type, children }: BlockObject | ListObject | ListItemObject,
  index: number
) {
  switch (type) {
    case Elements.blockItem:
      const firstChild = children[0];

      return (
        <FormattedText
          as={
            firstChild.type === Elements.anchor
              ? "div"
              : getTagFromSize(firstChild.size)
          }
        >
          {children.map((item, index) => getFormattedText(item, index))}
        </FormattedText>
      );

    case Elements.listItem:
      return (
        <Li key={index}>
          {children.map((item, index) => getFormattedText(item, index))}
        </Li>
      );

    case Elements.unorderedList:
      return (
        <ul key={index}>
          {children.map((value, index) => formatRichText(value, index))}
        </ul>
      );

    case Elements.orderedList:
      return (
        <ol key={index}>
          {children.map((value, index) => formatRichText(value, index))}
        </ol>
      );
    default:
      console.warn(`unsupported element: ${type}`);

      return <></>;
  }
}

const FormattedText = styled("span", {
  margin: 0,

  variants: {
    bold: {
      true: {
        fontFamily: "$primaryBold",
        fontWeight: "bold",
      },
    },
    italic: {
      true: {
        fontStyle: "italic",
      },
    },
    fontSize: {
      small: {
        fontFamily: "$primary",
        fontSize: "$text200",

        "@md": {
          fontSize: "$text300",
        },
      },
      normal: {
        fontFamily: "$primary",
        fontSize: "$text300",

        "@md": {
          fontSize: "$text400",
        },
      },
      large: {
        fontFamily: "$secondary",
        fontSize: "$text500",
        lineHeight: "$tighter",

        "@md": {
          fontSize: "$text600",
        },
      },
      "x-large": {
        fontFamily: "$secondary",
        fontSize: "$text700",
        lineHeight: "$tighter",

        "@maxsm": {
          fontSize: "$text600",
        },

        "@md": {
          fontSize: "$text800",
        },
      },
    },
  },
  compoundVariants: [
    {
      bold: false,
      fontSize: "small",
      css: {
        fontFamily: "$primary",
      },
    },
    {
      bold: true,
      fontSize: "small",
      css: {
        fontFamily: "$primaryBold",
      },
    },
    {
      bold: false,
      fontSize: "medium",
      css: {
        fontFamily: "$primary",
      },
    },
    {
      bold: true,
      fontSize: "medium",
      css: {
        fontFamily: "$primaryBold",
      },
    },
    {
      bold: false,
      fontSize: "large",
      css: {
        fontFamily: "$secondary",
      },
    },
    {
      bold: true,
      fontSize: "large",
      css: {
        fontFamily: "$secondaryBold",
      },
    },
    {
      bold: false,
      fontSize: "x-large",
      css: {
        fontFamily: "$secondary",
      },
    },
    {
      bold: true,
      fontSize: "x-large",
      css: {
        fontFamily: "$secondaryBold",
      },
    },
  ],
});

const Li = styled("li", {
  listStylePosition: "inside",
});

const Anchor = styled("a", {
  span: {
    textDecoration: "underline",
  },
});
